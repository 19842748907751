'use client'

import { Button, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { CancelCircleFilled24 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  handleClose: () => void
}

const BumpOrderItemDeletedError = ({ handleClose }: Props) => {
  const translate = useTranslate(
    'extra_services.checkout.order_status_modal.push_up_order.item_deleted',
  )

  return (
    <Cell>
      <Spacer size={Spacer.Size.Large} />
      <div className="u-flexbox u-justify-content-center">
        <Icon name={CancelCircleFilled24} testId="bump-failure-icon" color={Icon.Color.Warning} />
      </div>
      <Spacer size={Spacer.Size.X2Large} />
      <Text
        as="h1"
        text={translate('title')}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
        type={Text.Type.Heading}
      />
      <Spacer size={Spacer.Size.Large} />
      <Text
        as="span"
        text={translate('explanation')}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
      />
      <Spacer size={Spacer.Size.X2Large} />
      <Button
        text={translate('action')}
        styling={Button.Styling.Filled}
        onClick={handleClose}
        testId="continue-button"
      />
    </Cell>
  )
}

export default BumpOrderItemDeletedError
