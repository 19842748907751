'use client'

import { Spacer, Text, Badge } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

const BestValueBadge = () => {
  const translate = useTranslate('bump_items.selection_confirmation_modal')

  return (
    <>
      <Spacer size={Spacer.Size.Small} orientation={Spacer.Orientation.Horizontal} />
      <Badge
        theme="success"
        content={
          <Text
            as="h4"
            theme="inverse"
            type={Text.Type.Caption}
            text={translate('duration.best_value_badge_text')}
          />
        }
      />
      <Spacer size={Spacer.Size.Small} orientation={Spacer.Orientation.Horizontal} />
    </>
  )
}

export default BestValueBadge
